import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';
import useComponentState from '../../utils/useComponentState';
import { getVoucherQRbyCSV, getQrZpl } from '../../api/Vouchers';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import * as FileSaver from 'file-saver';

import './BulkPurchaseModal.scss';

const VoucherCSVUploadModal = ({ token, show, handleClose }) => {
  const [{ loading, uploadedFile, error, customClass, apiResponse }, setState] =
    useComponentState({
      loading: false,
      uploadedFile: null,
      error: '',
      customClass: '',
      apiResponse: {},
    });

  const handleModalClose = () => {
    handleClearError();
    handleClose();
  };

  const connectPrinter = async () => {
    try {
      const device = await navigator.usb.requestDevice({
        filters: [{ vendorId: 0x0a5f }],
      });

      await device.open();
      await device.selectConfiguration(1);
      await device.claimInterface(0);

      return device;
    } catch (error) {
      console.error(error);
    }
  };

  const printZpl = async () => {
    try {
      const zpl = await getQrZpl(uploadedFile, token);
      const device = await connectPrinter();
      const encoder = new TextEncoder();
      const data = encoder.encode(zpl);

      await device.transferOut(1, data);
    } catch (error) {
      console.error(error);
      setState({
        apiResponse: {
          message: 'Please make sure the printer is connected and turned on :)',
          success: false,
        },
      });
    }
  };

  const handleDownload = async () => {
    handleClearError();
    if (acceptedFiles.length < 1 && fileRejections.length < 1) {
      setState({
        error: 'Please upload .csv to proceed',
        customClass: 'error',
      });
      return;
    }
    try {
      setState({ loading: true });
      const rawPdfResponse = await getVoucherQRbyCSV(uploadedFile, token);
      console.log(rawPdfResponse);
      const decodedPdfData = Buffer.from(rawPdfResponse, 'base64');
      console.log(decodedPdfData);
      const blob = new Blob([decodedPdfData], {
        type: 'application/pdf',
      });
      const date = new Date();
      const fileName = moment(date).format('DDMMYY-HHmmss'); // Change the filename as needed
      FileSaver.saveAs(blob, fileName);
      setState({
        apiResponse: {
          message: 'Voucher QR codes will be downloaded shortly!',
          success: true,
        },
      });
    } catch (error) {
      console.log(error);
      setState({
        apiResponse: {
          message: 'Oops, something went wrong. Please try again',
          success: false,
        },
      });
    }
    setState({ loading: false });
  };

  const handlePrint = async () => {
    handleClearError();
    if (acceptedFiles.length < 1 && fileRejections.length < 1) {
      setState({
        error: 'Please upload .csv to proceed',
        customClass: 'error',
      });
      return;
    }
    try {
      setState({ loading: true });
      printZpl();
      setState({
        apiResponse: {
          message: 'Voucher QR codes will be downloaded shortly!',
          success: true,
        },
      });
    } catch (error) {
      console.log(error);
      setState({
        apiResponse: {
          message: 'Oops, something went wrong. Please try again',
          success: false,
        },
      });
    }
    setState({ loading: false });
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setState({ uploadedFile: acceptedFiles[0] });
    if (fileRejections.length > 0 && acceptedFiles.length < 1) {
      setState({
        error:
          'You can only upload *.csv files, and the max no of files is restricted to 1',
        customClass: 'error',
      });
    } else if (acceptedFiles.length > 0) {
      setState({ error: '', customClass: 'success', apiResponse: {} });
    }
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,

    maxFiles: 1,
    accept: { 'application/csv': ['.csv'] },
  });

  const files = acceptedFiles.map((file) => (
    <div key={file.path} className='pt-1'>
      {file.path}
    </div>
  ));

  const handleClearError = () => {
    setState({
      error: '',
      customClass: '',
      apiResponse: {},
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => handleModalClose()}
      backdrop='static'
      size='md'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Print QR by CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div {...getRootProps()} className={`dropzone__custom ${customClass}`}>
          <input {...getInputProps()} onClick={() => handleClearError()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop your .csv file, or click to select files</p>
          )}
        </div>
        <aside
          style={{
            color: error && acceptedFiles.length < 1 ? 'red' : '#208637',
            textAlign: error && acceptedFiles.length < 1 ? 'center' : '',
          }}
        >
          {error && acceptedFiles.length < 1 ? <small>{error}</small> : files}
        </aside>
        <div style={{ paddingTop: '1rem' }}>
          <Button
            variant='primary'
            block
            onClick={handlePrint}
            disabled={loading}
          >
            {loading ? <Spinner animation='border' /> : 'Print with Zebra'}
          </Button>
          <Button
            variant='dark'
            block
            onClick={handleDownload}
            disabled={loading}
          >
            {loading ? <Spinner animation='border' /> : 'Download'}
          </Button>

          <div className='text-center'>
            <small
              className={
                apiResponse && apiResponse.success
                  ? 'text-success'
                  : 'text-danger'
              }
            >
              {apiResponse.message}
            </small>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VoucherCSVUploadModal;
