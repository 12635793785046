import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, CloseButton, Col, Row } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Loading, PageHeader, Pagination } from '../../components';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import useComponentState from './../../utils/useComponentState';
import {
  getVouchersByStatus,
  searchVouchers,
  activateVoucher,
  getQrPdf,
  getTransactions,
  getMerchantCodes,
  getCarrierPdf,
} from '../../api/Vouchers';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as FileSaver from 'file-saver';
import Search from '../../components/Search/Search.component';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Drawer } from 'rsuite';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {
  faSearch,
  faTimes,
  faClock,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VoucherActivationModal from '../../components/Modals/VoucherActivationModal';
import VoucherExtensionModal from '../../components/Modals/VoucherExtensionModal';
import VoucherCSVUploadModal from '../../components/Modals/VoucherCSVUploadModal';
import styles from './ViewVouchers.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import ViewMerchantViewModal from '../../components/Modals/MerchantCodesModal';

const ViewVouchers = (props) => {
  /* const dispatch = useDispatch(); */
  const [state, setState] = useComponentState({
    tableHeading: {
      column: [
        'Voucher ID',
        'Sender',
        'Created Date',
        'Receiver',
        'Amount',
        'Receiver Number',
      ],
    },
    transactionHeading: {
      column: ['Date', 'Description', 'Amount'],
    },
    voucherData: [],
    transactionDetails: [],
    merchantCodesDetails: [],
    tabItems: {
      status: [
        { key: 'ALL', label: 'All' },
        { key: 'ACTIVE', label: 'Active' },
        { key: 'PENDING_ACTIVATION', label: 'Activation Pending ' },
        { key: 'EXPIRED', label: 'Expired' },
      ],
      searchDropDown: [
        { key: 'id', label: 'Voucher ID', placeholder: 'Voucher ID' },
        /* { key: 'purchase', label: 'Purchase ID', placeholder: 'Purchase ID' }, */
        {
          key: 'mobile_no',
          label: 'Receiver Number',
          placeholder: 'Receiver Number',
        },
        {
          key: 'sender_name',
          label: 'Sender Name',
          placeholder: 'Sender Name',
        },
        {
          key: 'receiver_name',
          label: 'Receiver Name',
          placeholder: 'Receiver Name',
        },
        {
          key: 'created_date',
          label: 'Created Date',
          placeholder: 'YYYY-MM-DD',
        },
        { key: 'expiry_date', label: 'Expiry Date', placeholder: 'YYYY-MM-DD' },
      ],
    },
    label: 'Voucher ID',
    voucherExtensionToggle: true,
    currentPage: 0,
    pageSize: 100,
    searchPlaceholder: 'Voucher ID',
    searchId: 'id',
    receiverName: '',
    receiverMobile: '',
    searchKey: '',
    statusKey: 'ALL',
    voucherId: '',
    labelsToPrint: [],
    slicedVoucherData: [],
    dataToRender: [],
    notFoundMessage: '',
    fetchError: '',
    loading: false,
    totalPages: 0,
    searchedMerchantMessage: '',
    selectedVoucher: null,
    showVoucherDrawer: false,
    drawerData: null,
    isClearable: false,
    page: 1,
    activationLoading: false,
    mobileError: '',
    validated: false,
    showActivationModal: false,
    activationError: '',
    userName: '',
    receiverMobile: '',
    activationSuccess: false,
    extensionSuccess: false,
    isSelected: false,
    selectAll: false,
    checkedRows: [],
    showExtensionModal: false,
    renderDatePicker: false,
    extensionDate: '',
    extensionLabel: '',
    voucherExpiryDate: null,
    showTransactions: false,
    selectedTransaction: null,
    transactionLoading: false,
    merchantCodesLoading: false,
    transactionError: '',
    merchantCodesError: '',
    setSelectedTransactions: null,
    isVisble: true,
    setVisible: true,
    isDetailsOpen: false,
    setDetailsOpen: false,
    setSelectedDetailsOpen: null,
    setShowTransactions: false,
    showVoucherView: true,
    setShowVoucherView: true,
    setLoading: true,
    isloading: true,
    setIsLoading: true,
    showMerchantCodesModal: false,
    selectedRedemptionId: null,
    showUploadModal: false,
  });

  const {
    tableHeading,
    voucherData,
    label,
    loading,
    totalPages,
    currentPage,
    pageSize,
    receiverName,
    receiverMobile,
    searchPlaceholder,
    searchId,
    showVoucherDrawer,
    drawerData,
    searchKey,
    isClearable,
    tabItems,
    statusKey,
    fetchError,
    voucherId,
    activationLoading,
    mobileError,
    validated,
    showActivationModal,
    activationError,
    userName,
    activationSuccess,
    isSelected,
    selectAll,
    checkedRows,
    showExtensionModal,
    voucherExpiryDate,
    showTransactions,
    showMerchantCodesModal,
    transactionDetails,
    transactionLoading,
    transactionError,
    selectedTransaction,
    transactionHeading,
    selectedRedemptionId,
    showUploadModal,
    labelsToPrint,
  } = state;

  useEffect(() => {
    !searchKey && getVoucherDetails(0);
    //eslint-disable-next-line
  }, [statusKey]);

  const getTransactionDetails = async (voucherID) => {
    setState({ fetchError: '', transactionError: '' });
    if (!showTransactions) {
      setState({
        transactionLoading: true,
        showTransactions: !showTransactions,
      });
      try {
        const response = await getTransactions(
          props.auth.auth.token,
          voucherID
        );
        setState({
          transactionDetails: response,
        });
        setState({ transactionLoading: false });
      } catch (error) {
        setState({
          transactionError: 'Oops, something went wrong, Please try again.',
          transactionLoading: false,
        });
      }
    } else {
      setState({ transactionDetails: [], showTransactions: !showTransactions });
    }
  };

  const getVoucherDetails = async (currentPage) => {
    setState({ loading: true, voucherData: [], fetchError: '' });
    try {
      const response = await getVouchersByStatus(
        props.auth.auth.token,
        statusKey,
        currentPage,
        pageSize
      );
      setState({
        voucherData: response.list,
        totalPages: response.totalPageCount,
        currentPage: response.pageNo,
      });
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const connectPrinter = async () => {
    try {
      const device = await navigator.usb.requestDevice({
        filters: [{ vendorId: 0x0a5f }],
      });

      await device.open();
      await device.selectConfiguration(1);
      await device.claimInterface(0);

      return device;
    } catch (error) {
      console.error(error);
    }
  };

  const printNameLabels = async (isPrice) => {
    let zpl = '';
    labelsToPrint.forEach(({ ownerName, amount }) => {
      const fontsize =
        ownerName.length <= 20 ? 45 : ownerName.length <= 30 ? 40 : 25;

      const amountOffset =
        ownerName.length <= 20 ? 105 : ownerName.length <= 30 ? 100 : 95;

      zpl += `^XA
^FO0,${isPrice ? '50' : '65'}
^FB799,1,0,C,0
^CF0,${fontsize},${fontsize}
^C^FH\^FD${ownerName}^FS

      ${
        isPrice
          ? `^FO0,${amountOffset}
      ^FB799,1,0,C,0
      ^A0N,25,25
      ^C^FH\^FDRs ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}^FS`
          : ''
      }

^FO0,160
^FB799,1,0,C,0
^A0N,20,20
^C^FH\^FDThyaga.lk | One vouchers, 150+ merchants^FS
^XZ`;
    });

    try {
      const device = await connectPrinter();
      const encoder = new TextEncoder();
      const data = encoder.encode(zpl);
      const data1 = encoder.encode(`^XA
      ^FO0,70
      ^FB799,1,0,C,0
      ^CF0,35,35
      ^C^FH\^FDMost Outstanding Employee of the Year
^FS

      ^FO0,150
      ^FB799,1,0,C,0
      ^A0N,20,20
      ^C^FH\^FDThyaga.lk | One vouchers, 150+ merchants^FS
      ^XZ`);

      await device.transferOut(1, data);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrintCodes = async () => {
    let zpl = '';
    let index = 1;
    checkedRows.forEach((id) => {
      if (index % 3 === 1) {
        zpl += `^XA
^PW799
^LL799

^FO38,6^BQN,2,6^FDQA,https://thyaga.lk/checkbalance/${id}^FS
^FO70,220^A0N,28,28^FD${id}^FS`;
      } else if (index % 3 === 2) {
        zpl += `^FO302,6^BQN,2,6^FDQA,https://thyaga.lk/checkbalance/${id}^FS
^FO334,220^A0N,28,28^FD${id}^FS`;
      } else {
        zpl += `^FO566,6^BQN,2,6^FDQA,https://thyaga.lk/checkbalance/${id}^FS
^FO600,220^A0N,28,28^FD${id}^FS

^XZ`;
      }

      index++;
    });

    if (index % 3 !== 1) {
      zpl += `^XZ`;
    }

    try {
      const device = await connectPrinter();
      const encoder = new TextEncoder();
      const data = encoder.encode(zpl);

      await device.transferOut(1, data);
    } catch (error) {
      console.error(error);
    }
  };

  const printPdf = async () => {
    try {
      const request = { voucherIds: checkedRows };
      const token = props.auth.auth.token;
      const rawPdfResponse = await getQrPdf(request, token);
      const decodedPdfData = Buffer.from(rawPdfResponse, 'base64');
      const blob = new Blob([decodedPdfData], { type: 'application/pdf' });
      const date = new Date();
      const fileName = moment(date).format('DDMMYY-HHmmss'); // Change the filename as needed
      FileSaver.saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const printCarrier = async (to, from, body) => {
    try {
      const selected = checkedRows.map((id) => {
        return { voucherId: id, to, from, body };
      });

      const request = [...selected];

      const token = props.auth.auth.token;
      const rawPdfResponse = await getCarrierPdf(request, token);
      const decodedPdfData = Buffer.from(rawPdfResponse, 'base64');
      const blob = new Blob([decodedPdfData], { type: 'application/pdf' });
      const date = new Date();
      const fileName = 'Carrier_Board_' + moment(date).format('DDMMYY-HHmmss');
      FileSaver.saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabOnClick = (key) => {
    setState({
      statusKey: key,
      searchKey: '',
      isClearable: false,
    });
  };

  const handleShowActivationModal = () => {
    setState({ showActivationModal: true, showVoucherDrawer: false });
  };

  const handleShowMerchantCodes = () => {
    setState({ showMerchantCodesModal: true, showVoucherDrawer: false });
  };

  const validatePhoneNumber = (receiverMobile) => {
    if (receiverMobile) {
      if (isPossiblePhoneNumber(receiverMobile)) {
        setState({
          mobileError: '',
        });
      } else {
        setState({
          mobileError: 'Enter a valid mobile number',
        });
      }
    } else {
      setState({
        mobileError: "Mobile number can't be empty",
      });
    }
  };

  const validateDetails = () => {
    validatePhoneNumber(receiverMobile);
    if (
      receiverName &&
      receiverMobile &&
      isPossiblePhoneNumber(receiverMobile)
    ) {
      return true;
    }
    return false;
  };

  const handleActivationSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const isValidDetails = validateDetails();

    if (!isValidDetails) {
      setState({ validated: true });
    } else {
      setState({ activationLoading: true });
      /* dispatch(setLoading()); */
      try {
        const request = {
          voucherId: voucherId,
          name: receiverName,
          mobileNo: receiverMobile,
        };
        const token = props.auth.auth.token;
        const response = await activateVoucher(request, token);
        if (response && response.status === 'COMPLETE') {
          /* dispatch(setLoading()); */
          setState({
            activationSuccess: true,
            validated: false,
            activationError: '',
            activationLoading: false,
          });
          getVoucherDetails(currentPage);
        }
      } catch (error) {
        setState({
          activationError: 'Oops! Something went wrong. Please try again',
          activationLoading: false,
          activationSuccess: false,
        });
      }
    }
  };

  const handleSearch = () => {
    if (!isClearable) {
      const { searchKey } = state;
      if (searchKey) {
        setState({
          isClearable: true,
        });
        searchVoucherById(0);
      }
    } else {
      handleIdOnClear();
    }
  };

  const handleIdOnClear = () => {
    setState({
      searchKey: '',
      isClearable: false,
      notFoundMessage: null,
    });
    getVoucherDetails(0);
  };

  const handleMobileChange = (receiverMobile) => {
    if (validated) {
      setState({ receiverMobile });
      validatePhoneNumber(receiverMobile);
    } else {
      setState({ receiverMobile });
    }
  };

  const hideActivationModal = () => {
    setState({
      showActivationModal: false,
      voucherId: '',
      mobileError: '',
      validated: false,
      activationError: '',
      activationLoading: false,
      receiverName: '',
      receiverMobile: '',
      activationSuccess: false,
    });
  };

  const toggleExtensionModal = () => {
    setState({
      showExtensionModal: !showExtensionModal,
      showVoucherDrawer: false,
    });
  };

  const handleShowUploadModal = () => {
    setState({ showUploadModal: !showUploadModal });
  };

  const handleMerchantModalClose = () => {
    setState({
      showMerchantCodesModal: !showMerchantCodesModal,
      showVoucherDrawer: false,
      selectedTransaction: null,
    });
  };

  const handleSearchDropDownOnClick = (key, label, placeholder) => {
    getVoucherDetails(0);
    setState({
      label: label,
      searchPlaceholder: placeholder,
      searchId: key,
      searchKey: '',
      isClearable: false,
    });
  };

  const handleCheckBoxToggle = (rowId, event) => {
    event.stopPropagation();
    handleCheck(rowId);
  };

  const handleCheck = (rowId) => {
    if (checkedRows.includes(rowId)) {
      const updatedCheckedRows = checkedRows.filter((id) => id !== rowId);
      const updatedLabelsToPrint = labelsToPrint.filter(
        (label) => label.id !== rowId
      );
      setState({
        checkedRows: updatedCheckedRows,
        labelsToPrint: updatedLabelsToPrint,
      });
    } else {
      const newLabelToPrint = voucherData
        .filter((data) => data.id === rowId)
        .map(({ id, ownerName, amount }) => ({ id, ownerName, amount }))[0];
      setState({
        checkedRows: [...checkedRows, rowId],
        labelsToPrint: [...labelsToPrint, newLabelToPrint],
      });
    }
  };

  const handleSelectAll = (event) => {
    setState({ selectAll: event.target.checked });
    if (event.target.checked) {
      const selectedAll = voucherData.map((data) => data.id);
      const newLabels = voucherData.map(({ id, ownerName, amount }) => ({
        id,
        ownerName,
        amount,
      }));
      setState({
        checkedRows: [...new Set([...checkedRows, ...selectedAll])],
        labelsToPrint: [...new Set([...labelsToPrint, ...newLabels])],
      });
    } else {
      const updatedCheckedRows = checkedRows.filter(
        (id) => !voucherData.some((data) => data.id === id)
      );
      const updatedLabelsToPrint = labelsToPrint.filter(
        (label) => !voucherData.some((data) => data.id === label.id)
      );
      setState({
        checkedRows: updatedCheckedRows,
        labelsToPrint: updatedLabelsToPrint,
      });
    }
  };

  const searchOnChange = (event) => {
    const { searchId } = state;
    const inputValue = event.target.value;
    switch (searchId) {
      case 'mobile_no':
        /^\+?[0-9]*$/.test(inputValue) &&
          setState({
            searchKey: event.target.value,
            isClearable: false,
          });
        break;
      case 'id':
      case 'purchase':
        /^[a-zA-Z0-9]*$/.test(inputValue) &&
          setState({
            searchKey: event.target.value,
            isClearable: false,
          });
        break;
      case 'receiver_name':
      case 'sender_name':
        let str = inputValue.replace(/^\s+/, '');
        setState({
          searchKey: str,
          isClearable: false,
        });
        break;
      case 'created_date':
      case 'expiry_date':
        let formattedDate = inputValue.replace(/[^0-9]/g, '');
        if (formattedDate.length > 8) {
          formattedDate = formattedDate.substring(0, 8);
        }
        if (formattedDate.length > 6) {
          formattedDate = formattedDate.replace(
            /^([0-9]{4})([0-9]{2})/,
            '$1-$2-'
          );
        } else if (formattedDate.length > 4) {
          formattedDate = formattedDate.replace(/^([0-9]{4})/, '$1-');
        }
        setState({
          searchKey: formattedDate,
          isClearable: false,
        });
        break;
      default:
        setState({
          searchKey: event.target.value,
          isClearable: false,
        });
    }
  };

  const searchVoucherById = async (page) => {
    setState({
      loading: true,
      fetchError: '',
      statusKey: 'ALL',
    });
    try {
      const request = {
        searchKey: searchKey,
        searchId: searchId,
        page: page,
        pageSize: pageSize,
        sortBy: 'createdDate',
        order: 'desc',
      };
      const token = props.auth.auth.token;
      const response = await searchVouchers(request, token);
      setState({
        voucherData: response.list,
        totalPages: response.totalPageCount,
        currentPage: response.pageNo,
      });
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const renderMultipleSearch = () => {
    return (
      <InputGroup className='mb-3'>
        <DropdownButton
          as={InputGroup.Prepend}
          variant='secondary'
          title={label}
          id='input-group-dropdown-1'
        >
          {tabItems.searchDropDown.map((item) => {
            const { key, label, placeholder } = item;
            return (
              <Dropdown.Item
                key={key}
                onClick={() =>
                  handleSearchDropDownOnClick(key, label, placeholder)
                }
              >
                {label}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <Form.Control
          placeholder={searchPlaceholder}
          value={searchKey}
          onChange={searchOnChange}
          required
          maxLength={50}
        />
        <InputGroup.Append>
          <Button variant='secondary' onClick={handleSearch}>
            <FontAwesomeIcon icon={isClearable ? faTimes : faSearch} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  };

  const renderFilterByStatus = () => {
    const { statusKey, tabItems } = state;
    return (
      <Breadcrumb>
        {tabItems.status.map((item) => {
          const { key, label } = item;
          return (
            <Breadcrumb.Item
              key={key}
              active={key === statusKey}
              onClick={() => handleTabOnClick(key)}
            >
              {label}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  const handleVoucherPagination = (page) => {
    if (searchKey) {
      searchVoucherById(page);
    } else {
      getVoucherDetails(page);
    }
    setState({
      currentPage: page,
    });
  };

  const handleOnClick = (id) => {
    const rowData = voucherData.find((data) => data.id === id);
    setState({
      showVoucherDrawer: true,
      drawerData: rowData,
      voucherId: rowData.id,
      voucherExpiryDate: rowData.expiryDate,
    });
  };

  const handleDrawerOnHide = () => {
    setState({
      showVoucherDrawer: !showVoucherDrawer,
      transactionDetails: [],
      showTransactions: false,
      selectedTransaction: null,
    });
  };

  const handleReceiverChange = ({ target: { value } }) => {
    if (/^([a-zA-Z][a-zA-Z]*(\s[a-zA-Z]*)?)?$/.test(value)) {
      setState({
        receiverName: value,
      });
    }
  };

  const renderTransactionDetails = (data) => {
    const handleClose = () => {
      setState({
        showTransactions: true,
        selectedTransaction: null,
      });
    };

    const transaction = data;
    const transactionContent = [
      { label: 'Transaction Type', value: transaction.transactionType },
      { label: 'Description', value: transaction.description },
      { label: 'Amount', value: transaction.amount.toFixed(2) },
      {
        label: 'Running Balance',
        value: transaction.runningBalance.toFixed(2),
      },
      {
        label: 'Timestamp',
        value: moment(transaction.timestamp).format('DD/MM/YYYY hh:mm A'),
      },
      { label: 'Redemption ID', value: transaction.redemptionId },
      { label: 'Merchant', value: transaction.merchant },
      { label: 'Sub-Merchant', value: transaction.subMerchant },
      { label: 'Reference Code', value: transaction.referenceCode },
      { label: 'Status', value: transaction.status },
    ];

    const filteredTransactionSectionContent = transactionContent.filter(
      (item) =>
        item.value !== null && item.value !== undefined && item.value !== ''
    );

    return (
      <div className={`details_group ${styles.content}`}>
        <Card>
          <Card.Body>
            <div className={styles.header}>
              <button className={styles.closeButton} onClick={handleClose}>
                &times;
              </button>
            </div>
            {filteredTransactionSectionContent.map((item, index) => (
              <div
                key={index}
                className='detail_group_content'
                style={{ height: '1rem' }}
              >
                <span className='content_header'>{item.label}</span>
                <span
                  className='content_value'
                  style={item.style && item.style}
                >
                  {item.value ? item.value : 'N/A'}
                </span>
              </div>
            ))}
          </Card.Body>
        </Card>
      </div>
    );
  };

  const renderDrawer = () => {
    const handleRowClick = (redemptionId) => {
      const transaction = transactionDetails.find(
        (t) => t.redemptionId === redemptionId
      );

      setState({
        selectedTransaction: transaction,
        selectedRedemptionId: redemptionId,
      });
    };

    const data = drawerData;
    const voucherID = data.id;
    const primaryContent = [
      { label: 'Voucher Theme', value: data && data.theme },
      {
        label: 'Date Created',
        value: moment(data.createdDate)
          .add(5, 'hours')
          .add(30, 'minutes')
          .format('DD/MM/YYYY hh:mm A'),
      },
      {
        conditionalRender: true,
        label: 'Expiry Date',
        value: moment(data.expiryDate).format('DD/MM/YYYY hh:mm A'),
      },
      {
        label: 'Amount(LKR)',
        value: data.amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      {
        label: 'Status',
        value: data.status.toUpperCase(),
        style: data.status === 'EXPIRED' ? { color: 'red' } : null,
      },
      {
        label: 'Sender Name',
        value: data.sender,
      },
      { label: 'Receiver Name', value: data.ownerName },
      { label: 'Receiver Mobile No.', value: data.ownerMobile },
    ];

    return (
      <div className='purchase_drawer_root'>
        <div className='drawer_child'>
          <div className='header_group'>
            <span className='header_title'>Voucher ID</span>
            <span className='header_value'>{voucherID}</span>
          </div>
          {selectedTransaction ? (
            renderTransactionDetails(selectedTransaction)
          ) : (
            <>
              {showTransactions ? (
                <div className={`details_group ${styles.content}`}>
                  <DataTable
                    tableData={transactionDetails}
                    tableheading={transactionHeading}
                    handleOnClick={handleRowClick}
                    tag='transactionDetails'
                    fetchError={transactionError}
                    search_filter_loading={transactionLoading}
                  />
                </div>
              ) : (
                renderVoucherDetails(primaryContent)
              )}
            </>
          )}

          {data.status === 'PENDING_ACTIVATION' && (
            <div className='footer' style={{ padding: '0 15px' }}>
              <Button
                variant='success'
                block
                onClick={() => handleShowActivationModal()}
              >
                Activate Voucher
              </Button>
            </div>
          )}

          <div className='footer' style={{ padding: '0 15px' }}>
            <div
              className='button-container'
              style={{ display: 'flex', gap: '10px' }}
            >
              {selectedTransaction && (
                <Button
                  variant='success'
                  style={{ flex: selectedTransaction ? '1' : 'auto' }}
                  onClick={() => handleShowMerchantCodes()}
                >
                  VIEW MERCHANT CODES
                </Button>
              )}
              <Button
                variant='success'
                style={{ flex: selectedTransaction ? '1' : 'auto' }}
                onClick={() => {
                  if (showTransactions) {
                    setState({
                      showTransactions: false,
                      selectedTransaction: null,
                    });
                  } else {
                    getTransactionDetails(voucherID);
                  }
                }}
              >
                {showTransactions ? 'GO BACK' : 'View Transaction'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVoucherDetails = (data) => {
    return (
      <div className={`details_group ${styles.content}`}>
        <Card>
          <Card.Body>
            {data.map((item) => {
              return (
                <div className='detail_group_content'>
                  <span className='content_header'>{item.label}</span>
                  <span
                    className='content_value'
                    style={item.style && item.style}
                  >
                    {item.conditionalRender && (
                      <FontAwesomeIcon
                        className={styles.edit__icon}
                        icon={faEdit}
                        onClick={toggleExtensionModal}
                      />
                    )}
                    {item.value ? item.value : 'N/A'}
                  </span>
                </div>
              );
            })}
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <div className={styles.root_div}>
      <div>
        <PageHeader text='Vouchers' />
        <div className={styles.filter_block_status}>
          <span>Filter by status</span>
          {renderFilterByStatus()}
        </div>
        <div className={styles.search_area_block}>
          <div className={styles.search_area}>{renderMultipleSearch()}</div>
        </div>
        <div className={styles.print_button_block}>
          {/* Todo - render from a function*/}
          <div className='mb-3 d-flex align-items-end'>
            {checkedRows.length > 0 && (
              <>
                <span style={{ lineHeight: '24px' }}>
                  {checkedRows.length} voucher
                  {checkedRows.length > 1 ? 's' : ''} selected
                </span>
                <span>
                  <CloseButton
                    className='ml-2'
                    onClick={() => {
                      setState({ checkedRows: [] });
                    }}
                  />
                </span>
              </>
            )}
          </div>
          <div>
            <InputGroup className='mb-3'>
              <Button
                variant='secondary'
                className='mr-3'
                onClick={() => handleShowUploadModal()}
              >
                Codes by CSV
              </Button>
              <Dropdown className='mr-3'>
                <Dropdown.Toggle
                  disabled={checkedRows.length === 0}
                  variant='success'
                  id='dropdown-basic'
                >
                  Print Carrier
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => printCarrier(true, true, true)}>
                    With Sender & Receiver
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => printCarrier(false, true, true)}
                  >
                    With Sender Only
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => printCarrier(true, false, true)}
                  >
                    With Receiver Only
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => printCarrier(false, false, true)}
                  >
                    Message Only
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className='mr-3'>
                <Dropdown.Toggle
                  disabled={checkedRows.length === 0}
                  variant='primary'
                  id='dropdown-basic'
                >
                  Print QR Codes
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handlePrintCodes()}>
                    Print with Zebra
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => printPdf()}>
                    Download PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className='mr-3'>
                <Dropdown.Toggle
                  disabled={checkedRows.length === 0}
                  variant='primary'
                  id='dropdown-basic'
                >
                  Name Labels
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => printNameLabels(true)}>
                    With Amount
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => printNameLabels(false)}>
                    Without Amount
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </div>
        </div>
        .
        <div>
          <CheckableDataTable
            tableData={voucherData}
            tableheading={tableHeading}
            isSelected={isSelected}
            tag='voucher-details'
            fetchError={fetchError}
            notFoundMessage={fetchError}
            search_filter_loading={loading}
            searchFilterLoading={false}
            handleOnClick={handleOnClick}
            handleCheck={handleCheckBoxToggle}
            handleSelectAll={handleSelectAll}
            checkedRows={checkedRows}
            selectAll={selectAll}
          />
          {!loading && !fetchError && (
            <Pagination
              pageCount={totalPages}
              onChange={handleVoucherPagination}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
      <Drawer show={showVoucherDrawer} onHide={handleDrawerOnHide}>
        {drawerData && renderDrawer()}
      </Drawer>

      <VoucherActivationModal
        showModal={true}
        handleSubmit={handleActivationSubmit}
        showActivationModal={showActivationModal}
        loading={activationLoading}
        mobileError={mobileError}
        validated={validated}
        activationError={activationError}
        handleClose={hideActivationModal}
        receiverName={receiverName}
        receiverMobile={receiverMobile}
        handleReceiverChange={handleReceiverChange}
        handleMobileChange={handleMobileChange}
        voucherId={voucherId}
        activationSuccess={activationSuccess}
      />

      <VoucherExtensionModal
        showActivationModal={showExtensionModal}
        handleClose={toggleExtensionModal}
        voucherId={voucherId}
        token={props.auth.auth.token}
        voucherExpiryDate={voucherExpiryDate}
        searchId={searchId}
        getVoucherDetails={getVoucherDetails}
        searchVoucherById={searchVoucherById}
      />

      <ViewMerchantViewModal
        showMerchantCodesModal={showMerchantCodesModal}
        handleModalClose={handleMerchantModalClose}
        token={props.auth.auth.token}
        redemptionId={selectedRedemptionId}
        handlemodalclose={handleMerchantModalClose}
      />
      <VoucherCSVUploadModal
        token={props.auth.auth.token}
        show={showUploadModal}
        handleClose={handleShowUploadModal}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ViewVouchers);
