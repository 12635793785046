import React, { useState } from 'react';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './CheckableDataTable.module.scss';

const CheckableDataTable = ({
  tableData = [],
  tableheading = { column: [] },
  merchantName,
  tag,
  fetchError = '',
  search_filter_loading = false,
  notFoundMessage = '',
  handleOnClick = () => {},
  renderTerminalsDropdown = () => null,
  onSubMerchantsChange,
  handleCheck,
  handleSelectAll,
  checkedRows,
}) => {
  const [subMerchantRows, setSubMerchantRows] = useState([]);
  const [selectedSubMerchants, setSelectedSubMerchants] = useState([]);

  const checkedRowsList =
    tag === 'subMerchants' ? subMerchantRows : checkedRows;

  const handleCheckSubMerchants = (id) => {
    const isChecked = subMerchantRows.includes(id);
    let newCheckedRows;
    let newSelectedSubMerchants = [...selectedSubMerchants];

    if (isChecked) {
      newCheckedRows = subMerchantRows.filter((rowId) => rowId !== id);
      newSelectedSubMerchants = newSelectedSubMerchants.filter(
        (item) => item.subMerchantId !== id
      );
    } else {
      newCheckedRows = [...subMerchantRows, id];
      newSelectedSubMerchants.push({
        subMerchantId: id,
        merchantName,
        subMerchantLocation: tableData.find((row) => row.id === id)?.location,
        terminals: tableData.find((row) => row.id === id)?.terminals || [],
      });
    }

    setSubMerchantRows(newCheckedRows);
    setSelectedSubMerchants(newSelectedSubMerchants);
    onSubMerchantsChange(newSelectedSubMerchants);
  };

  const handleCheckAllSubMerchants = () => {
    const allIds = tableData.map((row) => row.id);
    let newCheckedRows;
    let newSelectedSubMerchants = [];

    if (subMerchantRows.length === tableData.length) {
      newCheckedRows = [];
    } else {
      newCheckedRows = allIds;
      newSelectedSubMerchants = allIds.map((id) => ({
        subMerchantId: id,
        merchantName,
        subMerchantLocation: tableData.find((row) => row.id === id)?.location,
        terminals: tableData.find((row) => row.id === id)?.terminals || [],
      }));
    }

    setSubMerchantRows(newCheckedRows);
    setSelectedSubMerchants(newSelectedSubMerchants);
    onSubMerchantsChange(newSelectedSubMerchants);
  };

  const renderTableHeadings = (id) => {
    return tableheading.column.map((data, index) => (
      <th key={index} className={styles.table__heading} align='center'>
        {index === 0 && tableData.length > 0 && (
          <input
            className={styles.checkbox}
            type='checkbox'
            checked={tableData.every((rowData) =>
              checkedRowsList.includes(rowData[id])
            )}
            onChange={
              tag === 'subMerchants'
                ? handleCheckAllSubMerchants
                : handleSelectAll
            }
          />
        )}
        {data}
      </th>
    ));
  };

  const renderCheckboxColumn = (id, onClickEvent) => {
    const isChecked = checkedRowsList.includes(id);
    return (
      <td>
        <div className={styles.checkbox__column}>
          <input
            className={styles.checkbox}
            type='checkbox'
            checked={isChecked}
            onClick={
              onClickEvent ? (event) => handleCheck(id, event) : undefined
            }
            onChange={
              tag === 'subMerchants' ? () => handleCheckSubMerchants(id) : null
            }
          />
          <span className={styles.data}>{id}</span>
        </div>
      </td>
    );
  };

  const renderSubMerchantData = (data) => {
    return (
      <tr className={styles.table_row} key={data.id}>
        {renderCheckboxColumn(data.id)}
        <td>{data.emails?.join(', ') || 'N/A'}</td>
        <td>{data.mobileNumber?.join(', ') || 'N/A'}</td>
        <td>{data.location || 'N/A'}</td>
        <td data-label='Terminals' style={{ width: '10px' }}>
          {renderTerminalsDropdown(data.terminals) || 'N/A'}
        </td>
      </tr>
    );
  };

  const renderTableData = () => {
    switch (tag) {
      case 'ip-logs':
        return tableData.map(renderIPDetails);
      case 'otp-logs':
        return tableData.map(renderOTPDetails);
      case 'voucher-details':
        return tableData.map(renderVoucherDetails);
      case 'pending-purchases':
        return tableData.map(renderPendingPurchases);
      case 'subMerchants':
        return tableData.map(renderSubMerchantData);
      default:
        return null;
    }
  };

  const renderTableHeader = () => {
    switch (tag) {
      case 'ip-logs':
        return renderTableHeadings('ipAddress');
      case 'otp-logs':
        return renderTableHeadings('recipient');
      case 'voucher-details':
      case 'pending-purchases':
        return renderTableHeadings('id');
      case 'subMerchants':
        return renderTableHeadings('id');
      default:
        return null;
    }
  };

  const renderIPDetails = (data) => (
    <tr
      className={styles.table_row}
      key={data.ipAddress}
      onClick={() => handleCheck(data.ipAddress)}
    >
      {renderCheckboxColumn(data.ipAddress)}
      <td>{data.count}</td>
      <td>{moment(data.blockedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
      <td>{moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
    </tr>
  );

  const renderOTPDetails = (data) => (
    <tr
      className={styles.table_row}
      key={data.recipient}
      onClick={() => handleCheck(data.ipAddress)}
    >
      {renderCheckboxColumn(data.recipient)}
      <td>{data.time}</td>
      <td>{data.invalid}</td>
      <td>{data.sent}</td>
      <td>{moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
    </tr>
  );

  const renderVoucherDetails = (data) => {
    return (
      <tr
        className={styles.table_row}
        onClick={() => handleOnClick(data.id)}
        key={data.id}
      >
        {renderCheckboxColumn(data.id, true)}
        <td>{data.sender}</td>
        <td>{moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
        <td>{data.ownerName}</td>
        <td>
          {data.amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </td>
        <td>{data.ownerMobile}</td>
      </tr>
    );
  };

  const renderPendingPurchases = (data) => (
    <tr
      className={styles.table_row}
      key={data.id}
      onClick={() => handleOnClick(data.id)}
    >
      {renderCheckboxColumn(data.id, true)}
      <td>{data.purchaseId}</td>
      <td>{moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
      <td>{data.createdBy || data.senderName}</td>
      <td>
        {data.amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
    </tr>
  );

  const renderNotFound = (content) => (
    <tr>
      <td
        colSpan={tableheading.column.length}
        className='text-secondary bg-white text-center'
      >
        <span className='py-4'>{content}</span>
      </td>
    </tr>
  );

  const renderTable = () => (
    <div className='w-100'>
      <Table striped bordered hover>
        <thead>
          <tr>{renderTableHeader()}</tr>
        </thead>
        <tbody>
          {search_filter_loading ? (
            <tr>
              <td
                colSpan={tableheading.column.length}
                className='text-secondary bg-white text-center'
              >
                <Spinner animation='border' />
              </td>
            </tr>
          ) : fetchError === '' && tableData.length > 0 ? (
            renderTableData()
          ) : notFoundMessage ? (
            renderNotFound(notFoundMessage)
          ) : (
            renderNotFound(`NO ${tag.toUpperCase()} FOUND`)
          )}
        </tbody>
      </Table>
    </div>
  );

  return <div className={styles.root__div}>{renderTable()}</div>;
};

export default CheckableDataTable;
